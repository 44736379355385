import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from '../reducers/rootReducer';
import storage from 'redux-persist/lib/storage';
import authReducer from '../reducers/authReducer';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['oidc','auth'],
};
const rootReducerState = combineReducers({ auth: rootReducer,oidc: authReducer });
const persistedReducer = persistReducer(persistConfig, rootReducerState);

// middleware
const middleware = [thunk];

// creating store
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));
const persistor = persistStore(store);
export { store, persistor };
// assigning store to next wrapper
