import apiCalls from "../api/apiCalls";
import { get } from "../utils/apiGet";
const FETCH_TRACK_AUDITLOGS = "fetchtrackauditlogs";
const CLEAR_USER_INFO = "clearUserInfo";
const SET_TOKEN = "setToken";
const REMOVE_TOKEN = "removeToken";
const REFERRAL_DETAILS_DATA = "setReferralList";
const fetchtrackauditlogs = (payload) => {
    return {
        type: FETCH_TRACK_AUDITLOGS,
        payload
    }
};

const clearUserInfo = () => {
    return { type: CLEAR_USER_INFO, payload: null }
}

const setToken = (payload) => {
    return {
        type: SET_TOKEN,
        payload
    }
}
const removeToken = (payload) => {
    return {
        type: REMOVE_TOKEN,
        token: null
    }
}
const getIpRegisteryData = () => {
    return async (dispatch) => {
        apiCalls.getIpRegistery().then((res) => {
            if (res.ok) {

                dispatch(fetchtrackauditlogs(res.data));
            }
        });
    }
}

const getAuthorizationData = (id) => {
    return async (dispatch) => {
        return get(`User/GetAuthorizationToken/${id ? id : ""}`).then(token => {
            dispatch(setToken(token.data));
            return token
          });
    }
}


const setReferralList = (payload) => {
    return {
      type: REFERRAL_DETAILS_DATA,
      payload
    }
  };


const getReferralList = (id,pageNo, pageSize) => {
    const skip = pageNo * pageSize - pageSize;
    const take = pageSize;
    return async (dispatch) => {
            let response = await apiCalls.getReferralData(id,take,skip);
            if(response.ok){
                if(response.data){ 
                    let MergeGridData = pageNo === 1 ? [...response.data] : [...response.data];
                    dispatch({ type: 'referralList', payload: MergeGridData,pageNo,loading:false });
                    } 
            }
     }
  }

let initialState = {
    trackAuditLogData: {},
    token: null,
    referralList:[]
};

const authReducer = (state, action) => {
    if (!state) {
        state = {
            ...initialState,
            ...state
        }
    }
    switch (action.type) {
        case FETCH_TRACK_AUDITLOGS:
            state = { ...state, trackAuditLogData: action.payload }
            return state;
                case REFERRAL_DETAILS_DATA:
                    state = { ...state, referralList:(action.pageNo === 1 ? [...action.payload] : [...state.referralList,...action.payload])}//loading:action.payload,error:action.payload
                    return state; 
        case SET_TOKEN:
            return { ...state, token: action.payload };
        case REMOVE_TOKEN:
            return { ...state, token: null }
        default:
            return state;
    }
}
export default (authReducer);
export { getIpRegisteryData,getAuthorizationData, fetchtrackauditlogs, clearUserInfo, authReducer,setToken,removeToken,getReferralList,setReferralList };
