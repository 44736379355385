import React from 'react';

const ConnectWalletComponent = () => {
  return (
    <>
      <div className="connect-wallet-connect">
        <div>
          <h2 className="apply-title">
            Your wallet is disconnected for
            <br /> Connect wallet to complete KYC process
          </h2>
          <p>If you have an amazing project that you did like to launch at Yellow Block, apply now!</p>
        </div>
      </div>
    </>
  );
};

export default ConnectWalletComponent;
