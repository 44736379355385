import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import logoWhite from '../../assets/images/Minnapad-Logo-hor.svg';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { useNavigate, Link } from 'react-router-dom';
import { changeNetwork } from '../../hooks/useChangeChain';
import ToasterMessage from '../../utils/tostermessage';
import popicon from '../../assets/images/connectwallet.svg';
import walletconnect from '../../assets/images/walletconnect.svg';
import { setUserID } from '../../reducers/rootReducer';
import { get } from '../../utils/apiGet';
import { store } from '../../store';
function Header() {
  const [modalShow, setModalShow] = React.useState(false);
  const { connectAsync, connectors } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { isConnected, address } = useAccount();
  const [customerData, setCustomerData] = useState<any>();
  const router = useNavigate();
  const currentRoute = window.location.pathname;
  const { switchNetwork } = changeNetwork();
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum?.on("accountsChanged", (account) => {
        if (isConnected) {
          getCustomerDetails(account);
          router('/user/profileview');
        }
      });
      window.ethereum?.on("chainChanged", async (chain) => {
        if (chain == process.env.REACT_APP_POLYGON_CHAIN_HEX_ID || chain == process.env.REACT_APP_POLYGON_CHAIN_NUMARIC_ID) {
          getCustomerDetails(address);
          router('/user/profileview');
        } else {
          await disconnectAsync();
          router('/user/home');
        }
      });
    }
  }, []);
  useEffect(() => {
    if (isConnected && window.location.pathname.includes("home")) {
      router('/user/profileview');
    }
  }, [isConnected])
  const [walletError, setWalletError] = useState<any>(null);

  const getCustomerDetails = (addr: string) => {
    setCustomerData(addr);
  };
  const handleAuth = async (connector) => {
    if (!window.ethereum && connector.id !== "walletConnect") {
      window.open(process.env.REACT_APP_METAMASK_DOWNLOAD_LINK, "_blank")
    } else {
      if (connector.id === "walletConnect")
        setModalShow(false);
      try {
        const { account, chain } = await connectAsync({ connector });
        const chai_hex: any = process.env.REACT_APP_POLYGON_CHAIN_HEX_ID;
        const chai_numaric: any = process.env.REACT_APP_POLYGON_CHAIN_NUMARIC_ID;
        if (chain.id == chai_hex || chain.id == chai_numaric) {
          getCustomerDetails(account);
          setModalShow(false);
          router('/user/profileview');
        } else {
          await disconnectAsync();
          await switchNetwork();
          await connectAsync({ connector });
          getCustomerDetails(account);
          setModalShow(false);
          router('/user/profileview');
        }
        setWalletError(null);
      } catch (error) {
        setWalletError(error.message)
      }
    }
  };
  const handleDisconnect = async () => {
    setWalletError(null);
    await disconnectAsync();
    router('/user/home');
  };
  const redirectToMintingScreen = () => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD, '_blank');

  }
  const homeRedirect = (path) => {
   window.open(process.env.REACT_APP_LINK_MINNAPAD, '_blank');
    router(path);
  }
  const daoRedirect = (path) => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD_DAO, '_blank');
    router(path);
  }
  const nftsRedirect = (path) => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD_NFTS, '_blank');
    router(path);
  }
  const inquiriesRedirect = (path) => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD_INQUIRIES, '_blank');
    router(path);
  }
  const blogRedirect = (path) => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD_BLOGS, '_blank');
    router(path);
  }
  const joinusRedirect = (path) => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD_JOINUS, '_blank');
    router(path);
  }

  const handleMinnapad = () => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD_NFTS, "_blank")
  };
  
  const handleMarketPlace = () => {
    window.open(process.env.REACT_APP_LINK_MARKET_PLACE, "_blank")
  };
  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="wallet-popup custm-btns-metamask"
      >
        <Modal.Header >
          <span className="icon md dark-close-icon c-pointer"onClick={() => setModalShow(false)}></span>
        </Modal.Header>
        <Modal.Body className="text-center p-3">
          <h4 className='bottom-line'>Connect Your Wallet</h4>
          <p className='mt-custom mb-4'>
            Connect with one of available wallet providers or create
            a new wallet.
          </p>
          {/* <Modal.Header onClick={() => setModalShow(false)}> */}
          <div className='custom-metamask-btns'>
               
               <div className="top"> {connectors.map(connector => 
               <>{connector.ready&&<Button key={connector.id} className="pop-cards top d-flex c-pointer meta-btn-style" type="button" onClick={() => handleAuth(connector)}>
                 {connector.id !== "walletConnect" && <Image src={popicon} alt="image" />}
                 {connector.id === "walletConnect" && <Image src={walletconnect} alt="image" />}
                 {connector.id !== "walletConnect" && <p>{connector.name}</p>}
                 {connector.id === "walletConnect" && <p>Wallet Connect</p>}
               </Button>}</>)}
               </div>
             </div>
          {/* </Modal.Header> */}

          <p className="bottom-para mb-2">
            we do not own private keys and cannot access your funds without your confirmation
          </p>
          <p className='mt-4'><b>Note : If you encounter difficulties connecting your wallet, please refresh your browser and try again.</b></p>
        </Modal.Body>
      </Modal>
      <ToasterMessage success={walletError} bg={"Danger"} isShowToaster={walletError != null} onCloseToast={()=>setWalletError(null)} />
      <Navbar className="text-white cust-head-active custom-right mobile-header-fixed" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href="" className={currentRoute === '/home' ? 'nav-link active' : 'nav-link '}>
            <Image src={logoWhite} alt="logo"
              onClick={() => redirectToMintingScreen()}
              className="c-pointer cust-logo-width" />
          </Navbar.Brand>          
          <div className="profile-section mobile-show">
            <div className="btn-leftspace">
              {!isConnected && (
                <Button className="head-btn" onClick={() => setModalShow(true)}>
                  {!isConnected && (
                    <>
                    <span className="icon md wallet"></span>
                      <span className=''>Connect Wallet</span>
                      
                    </>
                  )}
                </Button>
              )}
            </div>
            {isConnected && (
              <NavDropdown className='desk-menu-dropdown'
                title={
                  <div className="btn-leftspace">

                    {isConnected && (
                      <Button className="head-btn">
                        {isConnected && (
                          <>
                           <span className="icon md wallet"></span>
                            <span>
                              {address?.slice(0, 4) + '....' + address?.substring(address.length - 4, address.length)}
                            </span>
                           
                            {/* <button
                             className="wallet-connect"
                             onClick={handleDisconnect}
                             style={{ marginLeft: 10, borderRadius: 5 }}
                           >
                             Disconnect
                           </button> */}
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                } id="collasible-nav-dropdown"
              >
                {isConnected && (
                  <Nav.Link className='menu-hover d-flex align-items-center'
                    onClick={handleDisconnect}
                  >
                    <span className='icon md wallet-dark'></span>
                    <span className='ms-1' >
                      Disconnect
                    </span>
                  </Nav.Link>
                )}
              </NavDropdown>
            )}
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          
        <Navbar.Collapse id="navbarScroll" className=''>
          {/* <div className='desk-hidden'> */}
            <Nav className="text-white my-2 my-lg-0 navbar-nav custom-right" navbarScroll>
              <Nav.Link
                href=""
                onClick={handleMinnapad}
               // onClick={() => homeRedirect('/user/profileview')}
                className={currentRoute === '/user/profileview' ? 'nav-link active' : 'nav-link '}
              >
                {/* <Link className={currentRoute == '/user/profileview' ? 'nav-link active' : 'nav-link '} to={'/user/profileview'}> */}
                  Home
                {/* </Link> */}
              </Nav.Link>
              <Nav.Link
                href=""
                onClick={() => daoRedirect('/user/profileview')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link'}
              >
                <Link className={currentRoute == '' ? 'nav-link active sm-p-0' : 'nav-link sm-p-0'}>
                  DAOs
                </Link>
              </Nav.Link>

              <Nav.Link
                href=""
                onClick={handleMarketPlace}
                // onClick={() => homeRedirect('/user/profileview')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link '}
              >
                {/* <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link header-nav-tab'}> */}
                  Marketplace
                {/* </Link> */}
              </Nav.Link>
              {/* <Nav.Link
                href=""
                onClick={() => inquiriesRedirect('/user/profileview')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link '}
              >
                <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link '}>
                  Inquiries
                </Link>
              </Nav.Link>
              <Nav.Link
                href=""
                onClick={() => blogRedirect('/user/profileview')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link '}
              >
                <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link '}>
                  Blog
                </Link>
              </Nav.Link>
              <Nav.Link
                href=""
                onClick={() => joinusRedirect('/user/profileview')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link '}
              >
                <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link '}>
                  Join Us
                </Link>
              </Nav.Link> */}
            </Nav> 
            {/* <div className="profile-section">
            <div className="btn-leftspace ms-0">
              {!isConnected && (
                <Button className="head-btn" onClick={() => setModalShow(true)}>
                  {!isConnected && (
                    <>
                      <span className='wallet-before'>Connect Wallet</span>
                      <span className="icon md wallet"></span>
                    </>
                  )}
                </Button>
              )}
            </div>
            {isConnected && (
              <NavDropdown className='desk-menu-dropdown'
                title={
                  <div className="btn-leftspace">

                    {isConnected && (
                      <Button className="head-btn">
                        {isConnected && (
                          <>
                            <span>
                              {address?.slice(0, 4) + '....' + address?.substring(address.length - 4, address.length)}
                            </span>
                            <span className="icon md wallet"></span>
                            <button
                             className="wallet-connect"
                             onClick={handleDisconnect}
                             style={{ marginLeft: 10, borderRadius: 5 }}
                           >
                             Disconnect
                           </button>
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                } id="collasible-nav-dropdown"
              >
                {isConnected && (
                  <Nav.Link className='menu-hover d-flex align-items-center'
                    onClick={handleDisconnect}
                  >
                    <span className='icon md wallet'></span>
                    <span className='ms-1' >
                      Disconnect
                    </span>
                  </Nav.Link>
                )}
              </NavDropdown>
            )}
          </div> */}
            {/* </div> */}
        </Navbar.Collapse>
        <div className="profile-section mobile-hidden">
            <div className="btn-leftspace ms-0">
              {!isConnected && (
                <Button className="head-btn" onClick={() => setModalShow(true)}>
                  {!isConnected && (
                    <>
                    <span className="icon md wallet"></span>
                      <span className=''>Connect Wallet</span>
                      
                    </>
                  )}
                </Button>
              )}
            </div>
            {isConnected && (
              <NavDropdown className='desk-menu-dropdown'
                title={
                  <div className="btn-leftspace">

                    {isConnected && (
                      <Button className="head-btn">
                        {isConnected && (
                          <>
                           <span className="icon md wallet"></span>
                            <span>
                              {address?.slice(0, 4) + '....' + address?.substring(address.length - 4, address.length)}
                            </span>
                           
                            {/* <button
                             className="wallet-connect"
                             onClick={handleDisconnect}
                             style={{ marginLeft: 10, borderRadius: 5 }}
                           >
                             Disconnect
                           </button> */}
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                } id="collasible-nav-dropdown"
              >
                {isConnected && (
                  <Nav.Link className='menu-hover d-flex align-items-center'
                    onClick={handleDisconnect}
                  >
                    <span className='icon md wallet-dark'></span>
                    <span className='ms-1' >
                      Disconnect
                    </span>
                  </Nav.Link>
                )}
              </NavDropdown>
            )}
          </div>
          </Container>
      </Navbar>
      
    </>
  );
}


export default Header;
