import axios from 'axios';
import { getHeaders } from './api';
import { store } from '../store';
// import { useSelector } from 'react-redux';
const API_END_POINT = process.env.REACT_APP_API_END_POINT_KYCINFO;
const API_VERSION = 'api/v1/';


function getCustomerToken(){
  const token = store.getState().auth?.user?.token;
  console.log(token)
  return {
    headers:{
      Authorization:token
    }
  }
}

export function get(url: string) {
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,getHeaders());
}

export function updateisreferralpage(url: any, obj: any) {
  return axios.put(`${API_END_POINT}${API_VERSION}${url}`, obj,getCustomerToken());
}

export function isexistingreferralcode(url: string) {
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,getHeaders())
}

export function getReferralData(url: string) {
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,getHeaders())
}