import React, { useEffect, useState } from 'react';
import { connect, useSelector, } from 'react-redux';
import { get, getReferralData } from '../../utils/apiGet';
import Moment from "react-moment";
import moment from 'moment';
import { useAccount } from 'wagmi';
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import nodata from '../../assets/images/no-data.png';

function Referrals(props: any) {
  const [refferalData, setRefferalData] = useState([])
  const [customerData, setCustomerData] = useState({})
  const [Loader, setLoader] = useState(false);
  const pageSize = 10;
  const [pageNo, setPageNo] = useState(1);
  const [errorMsg, setErrorMsg] = useState(false);
  const customerRegisterDetails = useSelector((state: any) => state.auth.user);
  const [referralCopied, setreferralCopied] = useState(false)
  const [copied, setCpoied] = useState(false)
  const [walletId, setWalletId] = useState({})
  const [loadData, setLoadData] = useState()

  useEffect(() => {
    console.log(props?.customerId)
    if (props?.user?.id || props?.customerId) {
      //  getCustomerDetail()
      getRefferalData(1, 10)
    }
  }, []);

  const loadMore = () => {
    getRefferalData(pageNo, pageSize);
  }


  const getRefferalData = async (pageNo: any, pageSize: any) => {
    setLoader(true);
    if (refferalData.length == 0) {
      setLoader(true);
    }
    const skip = pageNo * pageSize - pageSize;
    const take = pageSize;

    await getReferralData(`User/GetReferralData/${props?.user?.id || props?.customerId}/${take}/${skip}`)
      .then((response) => {
        let _pageNo = pageNo + 1;
        setPageNo(_pageNo);
        let mergeData = pageNo == 1 ? [...response.data] : [...refferalData, ...response.data];
        setRefferalData(mergeData)
        setLoadData(response.data?.length >= 10)
        setLoader(false);
      })
      .catch((error) => {
        setErrorMsg(isErrorDispaly(error));
        setLoader(false);
      });
  };
  const isErrorDispaly = (objValue: any) => {
    if (objValue.data && typeof objValue.data === 'string') {
      return objValue.data;
    } else if (objValue.originalError && typeof objValue.originalError.message === 'string') {
      return objValue.originalError.message;
    } else {
      return 'Something went wrong please try again!';
    }
  };
  const handleReferralCopy = () => {
    setreferralCopied(true)
    setCpoied(false)
    setTimeout(() => setreferralCopied(false), 1000)
  }
  const handleAddressCopy = (item: any) => {
    setCpoied(true)
    setreferralCopied(false)
    setWalletId(item?.walletAddress)
    setTimeout(() => setCpoied(false), 1000)
  }
  return (
    <div className='container'>
            <div> 
        
          <div className='Personal-Details'>
            <div className='mt-2 mb-2'>
              <span className='profile-label '>My Referral Code :  </span>
              <span className='profile-value'>{customerRegisterDetails?.customerReferralCode || "--"}
                {customerRegisterDetails?.customerReferralCode && (<CopyToClipboard text={customerRegisterDetails?.customerReferralCode} options={{ format: 'text/plain' }}
                  onCopy={() => handleReferralCopy(customerRegisterDetails?.customerReferralCode)}
                >
                  <span className={!referralCopied ? 'icon md copy-icon c-pointer ms-0' : 'icon md check-icon'} />
                </CopyToClipboard>)}
              </span>
            </div>
            <h1 className='main-title mb-3'>Referral’s</h1>
          </div>
          <div className='sm-auto'>
            {refferalData.length > 0 ?
              <div>
                {refferalData?.map((item: any) => (
                  <div className='whitelist-rowcard mb-3'>
                    <div className='flex-width text-start'>
                      <p className='label-type'>Date</p>
                      <p className='label-value'>
                        <Moment format='DD/MM/YYYY'>{moment(new Date(item?.date), 'DD/MM/YYYY')}</Moment>
                      </p>
                    </div>

                    <div className='flex-width'>
                      <p className='label-type'>Name</p>
                      <span className='label-value'>{(item?.name == " " || !item.name) ? "-" : item?.name}</span>
                    </div>
                    <div className='flex-width'>
                      <p className='label-type'>Wallet Address</p>
                      <div className='label-value wallet-address'>{item?.walletAddress || "--"}
                        <CopyToClipboard text={item?.walletAddress} options={{ format: 'text/plain' }}
                          onCopy={() => handleAddressCopy(item)}
                        >
                          <span className={(copied && item?.walletAddress === walletId) ? 'icon md check-icon' : ' icon md copy-icon c-pointer ms-0'} />
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className='flex-width'>
                      <p className='label-type'>Is Membership</p>
                      <span className='label-value'>{item?.isMemberShip.toString() || "--"}</span>
                    </div>
                    {/* <div className='flex-width'>
              <p className='label-type'>Value</p>
              <p className='label-value'>5</p>
            </div>
            <div className='flex-width'>
              <p className='label-type'>Status</p>
              <p className='label-value'>Pending</p>
            </div> */}
                  </div>))}
                {loadData &&
                  (<div className="category-more text-center">
                    <p onClick={loadMore} className="c-pointer see-more mb-0">See More</p>
                    <div className="doenarrow-icon">
                      <span className="icon md see-more c-pointer" onClick={loadMore}></span>
                    </div>
                  </div>)
                }
              </div> :
              <div className='text-center'>
                <img src={nodata} width={100}></img>
                <h4 className="nodata-text">No Data Found</h4>
              </div>}
          </div>
          </div> 
          
    </div>
  );
}

const connectDispatchToProps = (auth: any) => {
  return { user: auth?.user }
}

export default connect(null, connectDispatchToProps)(Referrals);