import React from 'react';
import Image from "react-bootstrap/Image";
import 'react-multi-carousel/lib/styles.css';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { post } from '../../utils/api';
import { get } from '../../utils/apiGet';
import apiUploadPost from '../../utils/apiUploadPost';
import { useAccount } from 'wagmi';
import profileImg from '../../assets/images/profile.png';
import { Spinner } from 'react-bootstrap';

export default function ProfilePage() {
  const { address } = useAccount();
  const [loader, setLoader] = useState(false);

  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phoneNo: '',
    aboutUs: '',
    profilePicUrl: null,
    imageUrl: '',
    WalletAddress: null,
    id: null,
  });
  const [errorMsg, setErrorMsg] = useState(false);

  const handleChange = (e: any, key: any) => {
    const value = e.target.value;
    let _obj: any = { ...profile };
    _obj[key] = value;
    setProfile(_obj);
  };
  useEffect(() => {
    getCustomerDetail();
  }, []);
  const getCustomerDetail = async () => {
    setLoader(true);
    let response = await get(`User/CustomerDetails/${address}`);
    if (response) {
      setProfile(response.data);
      store.dispatch(setUserID(response.data));
      setLoader(false);
    } else {
      setErrorMsg(isErrorDispaly(response));
    }
  };



  const saveDetails = async () => {
    setLoader(true);
    let obj = {
      id: profile.id,
      name: profile.name,
      email: profile.email,
      phoneNo: profile.phoneNo,
      profilePicUrl: profile.profilePicUrl,
      walletAddress: address,
      aboutUs: profile.aboutUs,
    };
    post(`User/SaveUser`, obj)
      .then((res) => {
        if (res) {
          setLoader(false);
          getCustomerDetail();
        }
      })
      .catch((error) => {
        setErrorMsg(isErrorDispaly(error));
      });
  };

  const uploadToClient = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      uploadToServer(file);
    }
  };

  const uploadToServer = async (file: any) => {
    const body: any = new FormData();
    body.append('file', file);
    apiUploadPost(`/Upload/UploadFileNew`, body)
      .then((res) => res)
      .then((data) => {
        let _obj = { ...profile };
        _obj.profilePicUrl = data[0];
        setProfile(_obj);
      })
      .catch((error) => {
        setErrorMsg(isErrorDispaly(error));
      });
  };
  const isErrorDispaly = (objValue: any) => {
    if (objValue.data && typeof objValue.data === 'string') {
      return objValue.data;
    } else if (objValue.originalError && typeof objValue.originalError.message === 'string') {
      return objValue.originalError.message;
    } else {
      return 'Something went wrong please try again!';
    }
  };

  return (
    <>
      {errorMsg && (
        <Alert variant="danger">
          <span>{errorMsg}</span>
        </Alert>
      )}
      <Form>
        <div className=" profile-detailsview">
          <div className="row">
            <div className="col-12 profile-details">
              <Form.Group className="avthar-image">
                <Image
                  src={profile?.imageUrl || profile?.profilePicUrl || profileImg}
                  width="100"
                  height="100"
                  alt=""
                />
                <input type="file" name="myImage" className="icon md cam" onChange={uploadToClient} />
                {/* <button><span className="icon md cam" /></button> */}
              </Form.Group>
              <h2 className="person-name">My Profile</h2>
            </div>
          </div>
          <div className="container" id="profile">
            {errorMsg && (
              <Alert variant="danger">
                <p style={{ color: 'red', margin: 10 }}>{errorMsg}</p>
              </Alert>
            )}
            <div className="row mt-5">
              <div className="col-md-4 col-12 mobile-space-xs">
                <Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="mb-0">Name</Form.Label>
                  </div>
                  <Form.Control
                    type="text"
                    value={profile?.name}
                    onChange={(e) => handleChange(e, 'name')}
                    placeholder="Name"
                  />
                </Form.Group>
              </div>
              <div className="col-md-4 col-12 mobile-space-xs">
                <Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="mb-0">Phone No</Form.Label>
                  </div>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleChange(e, 'phoneNo')}
                    placeholder="Phone"
                    value={profile?.phoneNo}
                  />
                </Form.Group>
              </div>
              <div className="col-md-4 col-12 mobile-space-xs">
                <Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="mb-0">Email</Form.Label>
                  </div>

                  <Form.Control
                    type="text"
                    onChange={(e) => handleChange(e, 'email')}
                    placeholder="Email"
                    value={profile.email}
                  />
                </Form.Group>
              </div>
              <div className="col-12 mt-3">
                <Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="mb-0">Bio</Form.Label>
                  </div>
                  <Form.Control
                    as="textarea"
                    onChange={(e) => handleChange(e, 'aboutUs')}
                    placeholder="Bio"
                    value={profile.aboutUs}
                  />
                </Form.Group>
              </div>
              <div className="mt-5 text-end">
                <Button className="custom-btn active" disabled={loader} onClick={() => saveDetails()}>
                  <span>{loader && <Spinner size="sm" />} </span> Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
