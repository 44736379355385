import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import referral from '../../assets/images/referral.svg';
import { useNavigate,useParams} from 'react-router-dom';
import { updateisreferralpage } from '../../utils/apiGet';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

function Referral() {
    const router = useNavigate();
    const params=useParams()
    const customerRegisterDetails = useSelector((state: any) => state.auth.user);
    const [isLoading,setisLoading] = useState(false)

   const handleOk=async()=>{
    router(`/user/referralcode/${params.id}`)
   
    }
    const handleUpdateReferral= async()=>{
      setisLoading(true)
        let obj={
            customerId:params.id,
            status:"No"
          }
        await updateisreferralpage(`User/updateisreferralpage`,obj) 
        .then((response) => {
            if (response) {
                router('/user/profileview')
                setisLoading(false)
            }
          })
          .catch((error) => {
            setisLoading(false)
          });
    }
  return (
    <>
   <div className='referral-content'>
   <div className='text-center'>
    <img src={referral} ></img>
    <h1 className='detailview-title'>Do You Have A <span className='referral-text'>Referral</span> Code?</h1>
    <hr className='my-collection-hr'></hr>
    <p className='mb-3'>If you have a referral code, You will get 5% </p>
   </div>
   <div className='referral-input position-relative'>
  <div className="mt-4 text-center">
    <Button type="button" onClick={handleUpdateReferral} className="border-btn m-0 text-center btn-cancel">
    <span>{isLoading && <Spinner size="sm" className='loader-dark' />}</span>{' '}
    No
    </Button>
    <Button type="button"  onClick={handleOk} className="fill-btn m-0 ms-3 submit-spinner">
    Yes
    </Button>
</div>
   </div>
   </div>
    </>
  );
}


export default Referral;
