import React, { useState, useEffect } from 'react';
import Image from "react-bootstrap/Image";
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { useNavigate } from "react-router-dom";
import popicon from '../../assets/images/connectwallet.svg';
import walletconnect from '../../assets/images/walletconnect.svg';
import Button from 'react-bootstrap/Button';
import { changeNetwork } from '../../hooks/useChangeChain';
import ToasterMessage from '../../utils/tostermessage';
import loadimg from '../../assets/images/Minnapad-Logo.svg'
import Footer from '../Footer/Footer';
export default function Home() {
  const [connectionState, setConnectionState] = useState(false);
  const { connectAsync, connectors } = useConnect();
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const [walletError, setWalletError] = useState<any>(null);
  const router = useNavigate();
  const { switchNetwork } = changeNetwork();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true)
    if (isConnected) {
      setConnectionState(true);
      router('/user/profileview');
    } else {
      router('/user/home');
      setTimeout(()=>{
        setIsLoading(false)
      },2000)
     
      setConnectionState(false);
    }
  }, []);
  useEffect(() => {
    setIsLoading(true)
    if (window.ethereum) {
      window.ethereum.on('chainChanged', async (chain_id) => {
        if (chain_id != process.env.REACT_APP_POLYGON_CHAIN_HEX_ID && chain_id != process.env.REACT_APP_POLYGON_CHAIN_NUMARIC_ID) {
          await disconnectAsync();
          router('/user/home');
          setTimeout(()=>{
            setIsLoading(false)
          },2000)
        }
      });
      window.ethereum.on('accountsChanged', async (account) => {
        if (!isConnected) {
          await connectAsync();
        }
      });
    }
  }, []);
  const handleAuth = async (connector: any) => {
    if (!window.ethereum && connector.id!="walletConnect") {
      window.open(process.env.REACT_APP_METAMASK_DOWNLOAD_LINK, "_blank")
    } else {
      try {
        const { account, chain } = await connectAsync({ connector });
        const chai_hex: any = process.env.REACT_APP_POLYGON_CHAIN_HEX_ID;
        const chai_numaric: any = process.env.REACT_APP_POLYGON_CHAIN_NUMARIC_ID;
        if (chain.id == chai_hex || chain.id == chai_numaric) {
          router('/user/profileview');
        } else {
          await disconnectAsync();
          await switchNetwork();
          await connectAsync();
          router('/user/profileview');
        }
        setWalletError(null);
      } catch (error) {
        setWalletError(error.message);
      }
    }
  };
  return (
    <>
      {!connectionState && (<>
        {isLoading ? (
            <div className='text-center'>
              <div className='loading-overlay'>
                <div className="text-center image-container">
            {/* <Spinner className="text-center" /> */}
            <Image
                      className=""
                      src={loadimg}
                      alt=""
                    />
          </div></div></div>
          ):<>
        <div className="connect-wallet-style">
          <div className="profile-panel text-center connect-width p-5">
            {/* <>
              <h4 className='connect-title mb-5 bottom-line'>Connect Your Wallet</h4>
             
              <div className='custom-metamask-btns'>
              
                <div className="top"> {connectors.map(connector => <Button key={connector.id} className="pop-cards top d-flex c-pointer meta-btn-style" type="button" onClick={() => handleAuth(connector)}>
                  {connector.id !== "walletConnect" && <Image src={popicon} alt="image" />}
                  {connector.id === "walletConnect" && <Image src={walletconnect} alt="image" />}
                  {connector.id !== "walletConnect" && <p>{connector.name}</p>}
                  {connector.id === "walletConnect" && <p>WalletConnect</p>}
                </Button>)}
                </div>
              </div>
              <p className="bottom-para mb-1">
                we do not own private keys and cannot access your funds without your confirmation
              </p>
              <p className='note'><b>Note : If you encounter difficulties connecting your wallet, please refresh your browser and try again.</b></p>
            </> */}

               <h4 className='connect-title mb-5 bottom-line'>Please click <span style={{color:'#0068ff'}}>connect wallet</span> button for connect your wallet</h4>
  </div>
        </div>
       
        <div className='wallet-toaster'>
        <ToasterMessage success={walletError} bg={"Danger"} isShowToaster={walletError != null} onCloseToast={() => setWalletError(null)} />
        </div></>}
      </>)}
    </>
  );
}
