import apiCalls from '../api/apiCalls'
const SET_USER_ID = 'setUserID';
const SET_IS_STAKER = 'Staker';
const WALLET_ADDRESS = 'walletAddress';
const setUserID = (payload) => {
  return {
    type: SET_USER_ID,
    payload,
  };
};
const Staker = (payload) => {
  return {
    type: SET_IS_STAKER,
    payload,
  };
};
const walletAddress = (payload) => {
  return {
    type: WALLET_ADDRESS,
    payload,
  };
};



const initialState = {
  user: { id: '', name: '' },
  isStaker: false,
  address: null,
};

const rootReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_ID:
      state = { ...state, user: payload };
      return state;
    case SET_IS_STAKER:
      state = { ...state, isStaker: payload };
      return state;
    case WALLET_ADDRESS:
      state = { ...state, address: payload };
      return state;
    default:
      return state;
  }
};

export default rootReducer;
export { setUserID, Staker, walletAddress };
