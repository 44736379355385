import axios from 'axios';
import { store } from '../store';
const API_END_POINT = process.env.REACT_APP_API_END_POINT_KYCINFO;
const API_VERSION = 'api/v1/';

const apiUploadPost = async (endpoint: string, params: Record<string, unknown>) => {
  const token = store.getState().oidc.token;

  const result = await axios.post(API_END_POINT + `${API_VERSION}` + `${endpoint}`, params, {
    headers: {
      'content-type': 'image/png',
      Authorization:token
    },
  });
  return result.data;
};

export default apiUploadPost;
