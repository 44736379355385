import axios from 'axios';
import { store } from '../store';
const API_END_POINT = process.env.REACT_APP_API_END_POINT_KYCINFO;
const API_VERSION = 'api/v1/';

function getHeaders(){
  const token = store.getState().oidc.token;
  return {
    headers:{
      Authorization:token
    }
  }
}
export function get(url: string) {

  return axios.get(API_END_POINT + `${API_VERSION}${url}`,getHeaders());
}

export function postAudit(url: string, obj: any) {
  return axios.post(API_END_POINT + `${API_VERSION}${url}`,obj,getHeaders());
}

async function post(url: any, obj: any) {
  return await axios.post(`${API_END_POINT}${API_VERSION}${url}`, obj,getHeaders());
}
export { post,getHeaders };
