import { projectApi, ipRegistry,kycInfo } from ".";
import { ApiControllers } from "./config";
import CryptoJS from "crypto-js";
const customerDetails = (address) => {
  return kycInfo.get(ApiControllers.user + `CustomerDetails/${address}`)
}

const getAllcustomers = (take, skip, searchBy) => {
  return projectApi.get(ApiControllers.projects + `AllCustomersDetails/${take}/${skip}/${searchBy || null}`)
}

const getKYCInformation = (CustomerId) => {
  return kycInfo.get(ApiControllers.sumsub + `GetKYCInformation/${CustomerId}`)
}

const saveAdmin = (obj) => {
  return projectApi.post(ApiControllers.projects + `SaveProjectOwner`, obj)
}

const adminProfile = (id) => {
  return projectApi.get(ApiControllers.projects + `ProfileDetails/${id}`)
}

const changePassword = (obj) => {
  return projectApi.post(ApiControllers.projects + `ChangePassword`,obj)
}

const kycStatus = (obj) =>{
  return kycInfo.put(ApiControllers.projects + `KycStatus`,obj)
}

//----------------REFERRAL CALLS----------------------------

const getReferralData=(customerId,take,skip)=>{
  return kycInfo.get(ApiControllers.user+`GetReferralData/${customerId}/${take}/${skip}`)
}



const isErrorDispaly = (objValue) => {
  if ((objValue.status > 400 && objValue.status < 500) && objValue.status != 401) {
    return "Something went wrong please try again!";
  } else {
    if (objValue.data && typeof objValue.data === "string") {
      return objValue.data;
    } else if (objValue.data && objValue.data.title && typeof objValue.data.title) {
      return objValue.data.title;
    } else if (
      objValue.originalError &&
      typeof objValue.originalError.message === "string"
    ) {
      return objValue.originalError.message;
    } else {

      return typeof (objValue) === "object" && objValue.reason ? objValue.reason : "Something went wrong please try again!";
    }
  }
};
const uploadErrorDisplay = (objValue)=>{
	if ((objValue?.status >= 400 && objValue?.status < 500) && objValue?.status != 401) {
		return "Something went wrong please try again!";
	} else {
		if (objValue?.title && typeof objValue?.title) {
			return objValue?.title;
		}   else {
			return "Something went wrong please try again!";
		}
	}
}
const fetchAdminDetails = (sub) => {
  return projectApi.get(ApiControllers.projects + `AdminInfo/${sub}`)
}

const encryptValue = (msg, key) => {
	msg = typeof msg == "object" ? JSON.stringify(msg) : msg;
	let salt = CryptoJS.lib.WordArray.random(128 / 8);

	let key1 = CryptoJS.PBKDF2(key, salt, {
		keySize: 256 / 32,
		iterations: 10,
	});

	let iv = CryptoJS.lib.WordArray.random(128 / 8);

	let encrypted = CryptoJS.AES.encrypt(msg, key1, {
		iv: iv,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
	});
	return salt.toString() + iv.toString() + encrypted.toString();
};
const getIpRegistery = () => {
      return ipRegistry.get(`/check?access_key=`+process.env.REACT_APP_IPREGISTERY_KEY);
  };


let apiCalls = {
  customerDetails, getAllcustomers, isErrorDispaly, getKYCInformation,saveAdmin,adminProfile,changePassword,fetchAdminDetails,encryptValue,
  kycStatus,getIpRegistery,uploadErrorDisplay,getReferralData
}
export default apiCalls