import { create } from "apisauce";

const projectApi = create({
	baseURL: process.env.REACT_APP_API_END_POINT + "/api/v1/",
});
const kycInfo=create({
	baseURL: process.env.REACT_APP_API_END_POINT_KYCINFO + "/api/v1/",
});



const ipRegistry = create({
	baseURL: "https://api.ipstack.com",
});
export {
	projectApi,
	ipRegistry,
	kycInfo
};

