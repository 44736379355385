import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';
import ProfilePage from './components/profile.component';
import Header from './components/header.component';
import Home from './components/home.component/home';
import ProfileView from './components/profile.component/profileView';
import SumSub from './components/sumsub';
import { getIpRegisteryData, setToken } from "./reducers/authReducer"
import { connect } from 'react-redux';
import { get } from './utils/apiGet';
import Footer from './components/Footer/Footer';
import Referrals from './components/profile.component/referrals';
import Referral from './components/referrals.component/referral';
import ReferralsCode from './components/referrals.component/referralcode';
import { useSelector } from 'react-redux';
function App(props: any) {
  
  const customerId = useSelector((state: any) => state.auth?.user?.id);
  useEffect(() => { props.trackauditlogs() }, [])
  const router = createBrowserRouter([
    {
      path: "/user",
      element: <><Header />
        <Outlet />
        <Footer />
      </>,
   
      loader: async () => {
        const id = store.getState().auth?.user?.id;
        return get(`User/GetAuthorizationToken/${id ? id : ""}`).then(res => {
            store?.dispatch(setToken(res.data));
            return res.data
        })
    },

      children: [
        {
          path: "home",
          element: <Home />
        },
        {
          path: "profile",
          element: <ProfilePage />
        },
        {
          path: "profileview",
          element: <ProfileView />,

        },
        {
          path: "kyc",
          element: <SumSub />
        },
        {
          path: "referrals/:id",
          element: <Referral />
        },
        {
          path: "referralcode/:id",
          element: <ReferralsCode />
        },
        // {
        //   path: "auditlogs",
        //   element: <Auditlogs />
        // },
      ]
    },
    {
      path: "/",
      element: <Navigate to={"/user/home"} />
    }
  ]);
  return (
    <Provider store={store}>
      {/* <div> */}
        <RouterProvider router={router} />
      {/* </div> */}
    </Provider>
  );
}


const connectDispatchToProps = (dispatch: any) => {
  return {
    trackauditlogs: () => {
      dispatch(getIpRegisteryData());
    }
  }
}

export default connect(null, connectDispatchToProps)(App);
